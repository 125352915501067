  // 资金方详情弹出框
<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    title="资金方查看"
  >
    <formInfo
      :count="count"
      :componentList="formInfoConfig"
      :model="detailInfo"
    />
    <template slot="footer">
      <base-button
        label="关闭"
        @click="visibleState = false"
        type="default"
      />
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { formInfoConfig } from '../utils/details-config'
import { fundsproviderApi } from '@/api/productApi'
import { formatDate } from '@/utils/auth/common'
import { getDictLists } from '@/filters/fromDict'
export default {
  props: {
    visible: Boolean,
    keyId: String
  },
  data () {
    return {
      count: 0,
      detailInfo: {}
    }
  },
  components: { BaseDialog, FormInfo, BaseButton },
  computed: {
    formInfoConfig () {
      return formInfoConfig(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    capitalSideType () {
      return getDictLists('CAPITAL_TYPE')
    },
    borrowerType () {
      return getDictLists('BORROWER_TYPE')
    },
    replyStatus () {
      return getDictLists('CAPITAL_APPLY_STATUS')
    },
    sealMethod () {
      return getDictLists('SUPPLIERS_SEAL_TYPE')
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.detail()
      }
    }
  },
  methods: {
    // 详情表格数据
    detail () {
      fundsproviderApi
        .getFundsproviderList({ keyId: this.keyId })
        .then((res) => {
          if (res.code === '0') {
            this.detailInfo = { ...res.data }
            // 回显数据处理
            const [borrowerTypeobj] = this.borrowerType.filter((item) => item.dictId === res.data.borrowerType)
            this.detailInfo.borrowerTypeName = borrowerTypeobj ? borrowerTypeobj.dictName : ''
            const [replyStatusobj] = this.replyStatus.filter((item) => item.dictId === res.data.replyStatus)
            this.detailInfo.replyStatusName = replyStatusobj ? replyStatusobj.dictName : ''
            this.detailInfo.circleQuota = res.data.circleQuotaFlag === '1' ? '是' : '否'
            this.detailInfo.borrowerRequirementName = res.data.borrowerRequirementFlag === '0' ? '有' : '无'
            const [obj] = this.capitalSideType.filter((item) => item.dictId === res.data.capitalSideType)
            this.detailInfo.capitalSideType = obj ? obj.dictName : ''
            this.detailInfo.effectiveEndTime = formatDate(res.data.effectiveEndTime, 'YY-MM-DD')
            const [obj2] = this.sealMethod.filter(item => item.dictId === res.data.sealMethod)
            this.detailInfo.sealMethod = obj2 ? obj2.dictName : ''
            this.count++
          }
        })
    }
  }
}
</script>
