// 详情
export const formInfoConfig = (content) => {
  const span = 12
  return [
    {
      label: '资金方名称',
      prop: 'capitalSideName',
      span
    },
    {
      label: '资金方类型',
      prop: 'capitalSideType',
      span
    },
    {
      label: '资方产品名称',
      prop: 'productName',
      span
    },

    {
      label: '融资利率(%)',
      prop: 'productRate',
      span
    },
    {
      label: '融资额度(元)',
      prop: 'replyQuota',
      span
    },
    {
      label: '借款主体类型',
      prop: 'borrowerTypeName',
      span
    },
    {
      label: '盖章方式',
      prop: 'sealMethod',
      span
    },
    {
      label: '是否为循环额度',
      prop: 'circleQuota',
      span
    },
    {
      label: '额度有效期',
      prop: 'effectiveEndTime',
      span

    },
    {
      label: '申报状态',
      prop: 'replyStatusName',
      span

    },
    {
      label: '共借人要求',
      prop: 'borrowerRequirementName',
      span
    },
    {
      label: '资金方案担保措施',
      prop: 'guaranteeMeasures',
      span: 24,
      showAll: true
    },
    {
      label: '底层资产要求',
      prop: 'assetRequirement',
      span: 24,
      showAll: true
    },
    {
      label: '还款方式',
      prop: 'repayStyle',
      span: 24,
      showAll: true
    }
  ]
}
