
import BaseSelect from '@/components/common/base-select/base-select'
import { formatDate } from '@/utils/auth/common'

// 查询
export const formConfig = (content) => {
  return [
    {
      label: '资金方类型',
      prop: 'capitalSideType',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.capitalSideType,
        selectedField: ['dictId', 'dictName']
      }
    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.sprovidertOptions,
        selectedField: ['capitalSideKeyId', 'capitalSideName'],
        filterable: true
      }
      // on: {
      //   change: (data) => {
      //     content.changeCapitalSide(data)
      //   }
      // }
    },
    {
      label: '借款主体类型',
      prop: 'borrowerType',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.borrowerType,
        selectedField: ['dictId', 'dictName']
      }
    },
    {
      label: '申报状态',
      prop: 'replyStatus',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.replyStatus,
        selectedField: ['dictId', 'dictName']
      },
      isHidden: content.fold
    },
    {
      label: '资方产品名称',
      prop: 'productName',
      tag: BaseSelect,
      attrs: {
        filterable: true,
        placeholder: '请选择',
        options: content.productList,
        selectedField: ['productId', 'productName']
      },
      isHidden: content.fold
    }
  ]
}
// 表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      'show-overflow-tooltip': true
    },
    {
      label: '资金方类型',
      prop: 'capitalSideType',
      'show-overflow-tooltip': true,
      minWidth: '120px',
      formatter: row => {
        const [obj] = content.capitalSideType.filter((item) => item.dictId === row.capitalSideType)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '资方产品名称',
      prop: 'productName',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '融资利率(%)',
      prop: 'productRate',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '借款主体类型',
      prop: 'borrowerType',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return row.borrowerType === 0 ? '企业 ' : '个人'
      }

    },
    {
      label: '融资额度(元)',
      prop: 'replyQuota',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.replyQuota ? row.replyQuota.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '额度开始有效日期',
      prop: 'effectiveStartTime',
      minWidth: '135px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return formatDate(row.effectiveStartTime, 'YY-MM-DD')
      }
    },
    {
      label: '额度结束有效日期',
      prop: 'effectiveEndTime',
      minWidth: '135px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return formatDate(row.effectiveEndTime, 'YY-MM-DD')
      }
    },
    {
      label: '申报状态',
      prop: 'replyStatus',
      'show-overflow-tooltip': true,
      formatter: row => {
        console.log(content.replyStatus, row.replyStatus)
        const [obj] = content.replyStatus.filter((item) => item.dictId === row.replyStatus.toString())
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
// 详情
export const formInfoConfig = (content) => {
  const span = 12
  return [
    {
      label: '资金方名称',
      prop: 'capitalSideName',
      span
    },
    {
      label: '资金方类型',
      prop: 'capitalSideType',
      span
    },
    {
      label: '资方产品名称',
      prop: 'productName',
      span
    },

    {
      label: '融资利率(%)',
      prop: 'productRate',
      span
    },
    {
      label: '融资额度(元)',
      prop: 'replyQuota',
      span
    },
    {
      label: '借款主体类型',
      prop: 'borrowerTypeName',
      span

    },
    {
      label: '是否为循环额度',
      prop: 'circleQuota',
      span
    },
    {
      label: '额度有效期',
      prop: 'effectiveEndTime',
      span

    },
    {
      label: '申报状态',
      prop: 'replyStatusName',
      span

    },
    {
      label: '共借人要求',
      prop: 'borrowerRequirementName',
      span
    },
    {
      label: '资金方案担保措施',
      prop: 'guaranteeMeasures',
      span: 24,
      showAll: true
    },
    {
      label: '层资产要求',
      prop: 'assetRequirement',
      span: 24,
      showAll: true
    }
  ]
}
