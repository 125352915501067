<template>
  <div >
    <!-- 查询from -->
    <base-form
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        />
      </div>
    </base-form>
    <!-- 新增资金方 -->
    <div class="main-page-table__header">
      <base-button
        label="新增"
        @click="jump('/addfund')"
        icon="el-icon-plus"
      />
    </div>
    <!-- 表格 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :api="api"
      :getApi="'getPage'"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :dataSource.sync="tableData"
      :webPage="false"
      ref="tableData"
      class="main-page-table with-add"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          @click="contractDetail(scope.row)"
          content="查看"
          icon="iconfont iconchakan"
        />
        <icon-button
          @click.prevent="updataProduct(scope.row)"
          content="修改"
          icon="iconfont iconbianji3"
          v-if="scope.row.replyStatus !== 1"
        />
        <icon-button
          @click.prevent="delectProduct(scope.row)"
          content="删除"
          icon="iconfont iconshanchu1"
          v-if="scope.row.replyStatus !== 1"
        />
      </template>
    </base-table>
    <!-- 详情弹出框 -->
    <fund-details :visible.sync="visibleDetail" :keyId="keyId"/>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { formConfig, columnsConfig } from './utils/config'
import { fundManagementApi } from '@/api/fundManagementApi'
import { getDictLists } from '@/filters/fromDict'
import FundDetails from './components/fund-details.vue'
export default {
  name: 'fundmanagement',
  components: {
    BaseForm,
    BaseTable,
    BaseButton,
    IconButton,
    FundDetails
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      params: {},
      zhuti: [],
      loadCount: 0,
      tableData: [],
      list: [],
      productList: [],
      detailInfo: {},
      visibleDetail: false,
      sprovidertOptions: [],
      fold: false,
      count: 0,
      keyId: ''
    }
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return formConfig(this)
    },
    api () {
      return fundManagementApi
    },
    capitalSideType () {
      return getDictLists('CAPITAL_TYPE')
    },
    borrowerType () {
      return getDictLists('BORROWER_TYPE')
    },
    replyStatus () {
      return getDictLists('CAPITAL_APPLY_STATUS')
    }
  },
  mounted () {
    this.setInItData()
  },
  activated () {
    this.setInItData()
  },
  methods: {
    jump (url) {
      this.$router.push({ path: url })
    },
    // 初始化
    setInItData () {
      Promise.all([
        this.handleFilter(),
        this.getCapital(),
        this.getProductList()
      ])
    },

    // 资金方下拉菜单
    getCapital () {
      this.api.getFundsprovidertName().then((res) => {
        if (res.data) {
          res.data.forEach((item) => {
            this.sprovidertOptions.push({
              capitalSideKeyId: item,
              capitalSideName: item
            })
          })
        }
      })
    },
    // 获取资方产品下拉菜单
    getProductList () {
      const params = {
        capitalSideType: this.queryParas.capitalSideType,
        capitalSideName: this.queryParas.capitalSideName
      }
      this.api
        .getProductList(params)
        .then((res) => {
          if (res.data) {
            res.data.forEach((val) => {
              this.productList.push({ productName: val, productId: val })
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 数组处理
    setMap (data) {
      if (Array.isArray(data)) {
        return data.map((item, index) => {
          item = {
            name: item,
            id: index
          }
          return item
        })
      }
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 查询
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    // 修改
    updataProduct (data) {
      this.$router.push({
        path: '/addFund',
        query: { keyId: data.keyId }
      })
    },
    // 查看详情
    contractDetail (data) {
      this.visibleDetail = true
      this.keyId = data.keyId
    },
    // 删除
    delectProduct (data) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        console.log('1')
        this.api.DelectProductInfo({ keyId: data.keyId }).then((res) => {
          if (res.data) {
            this.success('删除成功')
            this.handleFilter()
          } else {
            this.error('删除失败')
          }
        })
      }).catch(() => {
        console.log('2')
      })
    }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped/>
